.tag {
    @apply bg-aass-dark-blue text-white uppercase px-6 py-3;

    font-size: 14px;
    line-height: 1;

    &--on-hero {
        position: absolute;
        display: inline-block;
        top: 0;
        transform: translate(-50%, -50%);
    }
}
