.responsive-iframe {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    min-height: 100px;
    max-height: 700px;
}

.responsive-iframe video,
.responsive-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container-canvas {
    max-width: 2400px;
}
