.event-card-meta {
    // @apply absolute bottom-0 inset-x-0 flex flex-wrap gap-3 justify-between py-3 px-5 bg-aass-dark-blue bg-opacity-80 text-white;

    &__item {
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 16px;
        line-height: 1;

        svg {
            width: 18px;
            position: relative;
            top: -1px;
        }
    }

    &__location {
        @apply hidden lg:flex;
        width: 100%;
    }

    &--concluded {
        .event-card-meta__item {
            opacity: 0.4;
            text-decoration: line-through;

            &:nth-child(2) {
                text-decoration: none;
                opacity: 1;
            }
        }
        svg {
        }
    }
}
