.event-meta {
    @apply flex bg-aass-dark-blue text-white px-5 py-7 flex-col md:flex-row flex-wrap lg:flex-nowrap gap-4 md:gap-6 lg:gap-10;
    justify-content: space-between;

    &__inner {
        @apply flex flex-col md:flex-row gap-4 md:gap-10 md:w-full lg:w-auto md:justify-center;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 16px;
        line-height: 1;

        svg {
            width: 18px;
            position: relative;
            top: -1px;
        }
    }

    &__cta {
        @apply text-left md:text-center lg:text-right;
        flex: 1;
    }
}
