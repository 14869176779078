/** BASE **/
@use "./common/" as *;

/** TAILWIND **/
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../node_modules/swiper/swiper.scss";
@import "../../node_modules/swiper/modules/navigation/navigation.scss";
@import "../../node_modules/swiper/modules/pagination/pagination.scss";
@import "../../node_modules/swiper/modules/keyboard/keyboard.scss";

@import "components/tag";
@import "components/event-meta";
@import "components/event-card-meta";

html {
  @apply text-sm md:text-base font-base leading-[160%] text-aass-dark-blue;
  font-variant-ligatures: none;
}

main {
  //compensate for header / nav which is h-14
  @apply pt-12;
}

/** TYPOGRAPHY **/
h1,
.h1 {
  @apply text-xl md:text-2xl font-bold uppercase;
}

.h1-smaller-mobile {
  font-size: 36px;
  line-height: 1.1;

  @media (min-width: 640px) {
    font-size: 46px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
  }
}

h2,
.h2 {
  @apply text-mobile-h2 md:text-lg font-bold uppercase;
}

h3,
.h3 {
  @apply text-mobile-h3 md:text-md font-bold uppercase;
}

h4,
.h4 {
  @apply text-mobile-h4 md:text-sm font-medium uppercase;
}

.link,
a {
  @apply font-medium text-base underline;

  &-sm {
    @apply font-bold text-xs;
  }
}

.h1-clamp {
  @apply font-bold uppercase;
  font-size: clamp(32px, 8.5vw, 60px);
}

.dynamic-banner-heading {
  font-size: clamp(36px, 5vw, 60px);
}

header {
  .nav {
    &__list-wrapper {
      @apply text-center md:col-span-4 xl:col-span-2 border-b border-b-[#E9E1CF] md:border-0 pb-8;

      & .nav__list-heading {
        @apply relative flex justify-center items-center text-center w-full after:block after:absolute after:top-0 after:right-0 after:w-8 after:h-8 after:bg-arrow-up after:bg-center after:bg-no-repeat md:after:hidden;

        & h3 {
          @apply font-medium text-base normal-case md:text-[20px] md:leading-[140%] text-aass-light-gold;
        }
      }
    }
    a {
      @apply no-underline font-base leading-[150%] md:leading-[180%];
    }
  }
}

.footer-body {
  @apply text-[16px] leading-[140%] pb-8;

  a {
    @apply font-base;
  }
}

/** UTILITIES **/
.button-grid {
  @apply flex flex-wrap gap-x-4 gap-y-[14px] md:gap-5 justify-center w-full max-w-[900px] mx-auto;
}

.decoration,
.decoration--gold {
  @apply flex gap-2 items-center justify-center
    before:block dark:before:bg-decoration before:bg-decoration-dark before:w-[31px] before:h-[10px] before:bg-center  before:bg-no-repeat
    after:block dark:after:bg-decoration after:bg-decoration-dark after:w-[31px] after:h-[10px] after:rotate-180 after:bg-center after:bg-no-repeat;

  &--gold {
    @apply before:bg-decoration-gold before:w-[55px] before:h-6
    after:bg-decoration-gold after:w-[55px] after:h-6;
  }
}

a.decoration {
  @apply no-underline;
}

.accordion {
  &__list {
    @apply flex flex-col gap-2;
  }

  &-item {
    &__title {
      @apply cursor-pointer;
    }
  }
}

.rich-text {
  &__content {
    @apply lg:px-0 flex flex-col gap-4 md:gap-6 justify-center lg:col-start-3 lg:col-span-8;

    h1,
    h2,
    h3,
    h4 {
      @apply mb-2;
    }

    .summary {
      @apply font-medium text-base md:text-md;
    }

    a {
      @extend .btn-blue;
      &.link {
        @apply text-aass-dark-blue bg-transparent min-w-[0] p-0 underline;
      }
      &.link:hover {
        @apply shadow-none text-aass-gold;
      }
    }

    ul {
      li {
        @apply list-disc ml-6;
      }
    }
  }
}

.btn-style-wrapper {
  a {
    @extend .btn-blue;
  }
}

.products-gradient {
  @apply -mt-px;
  background: linear-gradient(
    180deg,
    rgba(193, 220, 230, 1) 0%,
    rgba(193, 220, 230, 1) 50%,
    rgba(8, 60, 90, 1) 50%,
    rgba(8, 60, 90, 1) 100%
  );
}

.products-grid-gradient {
  @apply -mt-px;
  background: linear-gradient(
    180deg,
    rgba(193, 220, 230, 1) 0%,
    rgba(193, 220, 230, 1) 25%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
}

.custom-gradient-light-gold-25 {
  @apply -mt-px;
  background: linear-gradient(
    180deg,
    rgba(233, 225, 207, 1) 0%,
    rgba(233, 225, 207, 1) 25%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
}

.custom-gradient-light-gold-50 {
  @apply -mt-px;
  background: linear-gradient(
    180deg,
    rgba(233, 225, 207, 1) 0%,
    rgba(233, 225, 207, 1) 50%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

.cards-grid {
  &.two {
    & .cards-grid__item:first-child {
      @apply xl:col-span-2;
    }
  }

  &.one {
    & .cards-grid__item:first-child {
      @apply col-span-full;

      & .cards-grid__content {
        h3 {
          @apply max-w-[22ch] mx-auto;
        }
      }
    }
  }

  &__item,
  &__item--icon {
    @apply relative flex flex-col;

    &--green {
      @apply bg-aass-green;

      &.tag {
        @apply before:bg-aass-green;
      }
    }
    &--light-gold {
      @apply bg-aass-light-gold;

      &.tag {
        @apply before:bg-aass-light-gold;
      }
    }
    &--gold {
      @apply bg-aass-gold;

      &.tag {
        @apply before:bg-aass-gold text-white;
      }
    }
    &--light-blue {
      @apply bg-aass-light-blue;

      &.tag {
        @apply before:bg-aass-light-blue;
      }
    }
    &--blue {
      @apply bg-aass-blue;

      &.tag {
        @apply before:bg-aass-blue text-white;
      }
    }
    &--dark-blue {
      @apply bg-aass-dark-blue;

      &.tag {
        @apply before:bg-aass-dark-blue text-white;
      }
    }
    &--light-grey {
      @apply bg-aass-light-grey;

      &.tag {
        @apply before:bg-aass-light-grey;
      }
    }
    &--grey {
      @apply bg-aass-grey;

      &.tag {
        @apply before:bg-aass-grey text-white;
      }
    }

    &--green,
    &--light-gold,
    &--gold,
    &--light-blue,
    &--blue,
    &--dark-blue,
    &--light-grey,
    &--grey {
      @extend .cards-grid__item;

      &.tag {
        &::before {
          content: attr(data-tag);
        }
        @apply before:absolute before:top-0 before:right-0 before:px-4 before:py-1 before:uppercase before:text-xs;
      }
    }
  }

  &__item--icon {
    @apply dark:text-white w-full h-full;

    & h3 {
      @apply font-bold text-[22px] md:text-[28px] dark:text-aass-light-gold;
    }

    & p {
      @apply dark:text-white text-sm md:text-[20px];
    }

    & svg {
      @apply mb-8;

      &.tankbil {
        @apply mb-0;
      }
    }
  }

  &__content {
    @apply text-center p-8 pb-12 md:pb-16 h-full flex flex-col justify-between gap-6 dark:text-white;

    & h3 {
      @apply max-w-[20ch] mx-auto;
    }
  }
}

.image-caption-grid {
  @apply container flex flex-col lg:flex-row gap-5;
}

.information-card-grid {
  @apply container flex flex-col lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-5;
}

.image-caption-grid,
.information-card-grid {
  &__item {
    @apply flex-1 flex flex-col;

    img {
      @apply flex-1;
    }
  }

  &__content,
  &__content--green,
  &__content--blue,
  &__content--grey {
    @apply p-8 flex flex-col gap-6 justify-between h-full;

    &-main {
      @apply flex flex-col gap-4;
    }
    & h3 {
      @apply text-[28px] md:text-lg xl:text-[30px] 2xl:text-lg mb-4;
    }

    h1,
    h2,
    h3,
    h4 {
      @apply mb-2 font-bold lg:font-medium;
    }

    .summary {
      @apply font-medium;
    }

    a {
      @extend .btn-blue;
      @apply my-2;
    }
  }

  &__content--green {
    @apply bg-aass-green;
  }
  &__content--blue {
    @apply bg-aass-dark-blue text-white;

    a {
      @extend .btn-white;
    }
  }
  &__content--grey {
    @apply bg-aass-light-grey;
  }
}

.quote-grid {
  &__quote-wrapper {
    @apply bg-aass-dark-blue lg:col-span-7 xl:col-start-2 xl:col-span-6;
  }

  &__quote-content {
    @apply text-white px-8 sm:pl-20 lg:pl-8 xl:px-20 py-16 md:py-20 lg:py-16 xl:py-20 max-w-[50ch] lg:max-w-none 2xl:max-w-[58ch];

    & .quote-clamp {
      font-size: clamp(20px, 5vw, 30px);
      line-height: clamp(150%, 7vw, 160%);

      @media screen and (min-width: 1024px) {
        font-size: clamp(20px, 2vw, 30px);
        line-height: clamp(150%, 2vw, 160%);
      }

      @apply mb-8 md:mb-16 lg:mb-8 xl:mb-16;
    }
  }

  &__item {
    @apply flex flex-col relative overflow-hidden lg:col-span-5 xl:col-span-4;

    &-content {
      @apply flex flex-col gap-12 h-full justify-between bg-aass-light-gold pt-10 lg:pt-12 pb-16 lg:pb-20 text-center;
    }
  }
}

.contact-info-list {
  dl {
    @apply pl-6;

    &:not(:first-child) {
      @apply lg:border-l lg:border-aass-dark-blue;
    }
  }

  dt {
    @apply font-medium text-sm md:text-[20px];
  }

  dd {
    @apply text-sm md:text-base;

    a {
      @apply no-underline font-base;
    }
  }
}

.search {
  form {
    @apply pb-10 md:pb-16 lg:pb-32;
  }

  input {
    @apply block w-full px-4 pl-10 py-2 md:py-3 uppercase bg-white border border-aass-dark-blue
    focus:outline-none focus:border-aass-blue focus:ring-aass-blue focus:ring-2;
  }
  &-item,
  &-item--img {
    @apply grid gap-5 md:grid-cols-10;

    a {
      @extend .btn-blue;
    }
  }

  &-list {
    @apply grid gap-12 lg:gap-16;
  }

  &-item {
    .search-item {
      &__title {
        @apply md:col-span-full xl:col-span-9;
      }
      &__text {
        @apply md:col-span-full xl:col-span-9 flex flex-col gap-6;
      }
    }

    &--img {
      .search-item {
        &__title {
          @apply md:row-start-1 md:col-span-7 xl:col-span-6 md:ml-4;
        }
        &__img {
          @apply md:row-start-1 md:row-span-2 md:col-start-1 md:col-span-3 w-full h-full max-h-[230] lg:max-h-full object-cover;
        }
        &__text {
          @apply md:row-start-2 md:col-span-7 xl:col-span-6 md:ml-4 flex flex-col gap-5 justify-between;
        }
      }
    }
  }
}

.nav-search {
  @apply hidden lg:flex lg:gap-2 lg:items-center;

  input {
    @apply w-0 uppercase text-aass-dark-blue bg-aass-dark-blue border border-aass-dark-blue transition-width duration-75 ease-in
    focus:outline-none focus:border-aass-blue focus:ring-aass-blue focus:ring-2 focus:px-4 focus:py-2 focus:bg-white focus:w-full focus:transition-width focus:duration-75 focus:ease-in;
  }
}

.nav-search-mobile {
  @apply block lg:hidden md:col-start-3 md:col-span-8 md:mt-4;

  input {
    @apply w-full pr-4 pl-10 py-2 uppercase text-aass-dark-blue border border-aass-dark-blue bg-white
    focus:outline-none focus:border-aass-blue focus:ring-aass-blue focus:ring-2;
  }
}

.contact-form {
  @apply px-6 md:px-10 py-8 md:py-16 shadow-md md:shadow-lg;
}

input,
textarea,
select {
  @apply w-full px-4 py-2 text-sm text-aass-dark-blue bg-white border border-aass-dark-blue
  focus:outline-none focus:border-aass-blue focus:ring-aass-blue focus:ring-2;
}

select {
  @apply appearance-none pr-5 bg-no-repeat bg-[length:16px_12px] bg-[right_0.75rem_center] bg-[url('/img/hero-pattern.svg')];
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5L8 11L14 5' stroke='%23343A40' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

@layer components {
  .btn {
    @apply inline-block min-w-[160px] lg:min-w-[200px] max-w-max px-8 rounded-full text-white text-base leading-[36px] text-center hover:shadow-md no-underline;
  }
  .btn-blue {
    @extend .btn;
    @apply bg-aass-dark-blue hover:bg-aass-gold;
  }
  .btn-transparent {
    @extend .btn;
    @apply border-2 border-white hover:border-aass-light-blue hover:text-aass-light-blue;
  }
  .btn-gold {
    @extend .btn;
    @apply bg-aass-light-gold text-aass-dark-blue hover:bg-aass-gold hover:text-white;
  }
  .btn-white,
  .btn-white-alt {
    @extend .btn;
    @apply bg-white text-aass-dark-blue hover:bg-aass-light-blue;

    &-alt {
      @apply hover:bg-aass-gold hover:text-white;
    }
  }

  .btn-small {
    font-size: 16px;
    min-width: 100px;
    line-height: auto;
  }

  //used by card-grid items
  .tag-content {
    @apply before:absolute before:top-0 before:right-0 before:px-4 before:py-1 before:uppercase before:text-xs;
  }
}

/** Products, cards, slider / grid **/

.product-card {
  @apply grid place-items-center w-full h-full min-h-[463px] md:min-h-[490px] px-8 md:px-12 py-4 md:py-12;

  img {
    @apply object-cover;
  }
}

.products-slider {
  @apply w-full h-full;

  &__wrapper {
    @apply h-full;
  }

  &__slide {
    @extend .product-card;
    @apply bg-white;
  }

  a {
    @apply no-underline;
  }
}

.products-grid {
  @apply min-h-[450px];

  &__item {
    @apply bg-white w-full max-w-[80%] sm:max-w-none h-full sm:col-span-6 lg:col-span-4 xl:col-span-3 place-items-center md:shadow-xl sm:shadow-none sm:hover:shadow-xl;
  }

  img {
    @apply h-[291px] flex-auto;
  }

  &__content {
    @extend .product-card;
  }

  a {
    @apply no-underline;
  }
}

.nav-tabs {
  .nav-link {
    text-decoration: none;
  }
  .nav-link.active {
    color: rgba(8, 60, 90, var(--tw-text-opacity));
    border-color: rgba(8, 60, 90, var(--tw-text-opacity));
  }
}
.tab-pane {
  display: none;
  &.show {
    display: unset;
  }
}

.product-image-description {
  padding: 10px 0;
}

.ingredients-rich-text-wrapper {
  p {
    @apply flex flex-wrap justify-end;
  }
}

/** SECTION SPACING **/

section {
  @apply mb-16 md:mb-28;
}

section.cards-grid + section:not(.cards-grid) {
  @apply mt-16 md:mt-28;
}

section.cards-grid {
  @apply mb-4;
}

section.no-padding__after {
  @apply mb-0;
}
